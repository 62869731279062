import { IAppStore } from '@/models/store.model'
import { webApiService } from '@/services'
import { Box, Modal, Paper, Typography } from '@mui/material'
import { Button } from '@nextui-org/react'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import { useTradeinContext } from '../hooks/useTradeinContext'

type IData = {
  loading: boolean,
  open: boolean,
  form: {
    id_sbu: number,
    brand: { id_brand: number, discount: string, brand: string, value: string, label: string },
    model: { id_model: number, model: string, value: string, label: string, amount: number },
    capacity: { id_capacity: number, capacity: string, value: string, label: string }
  },
  brandList: any[],
  modelList: any[],
  capacityList: any[]
}

const CheckFeasibility = () => {
  const { ctx, saveCtx } = useTradeinContext()
  const userState = useSelector((store: IAppStore) => store.user)
  const [data, setData] = useState<IData>({
    loading: false,
    open: false,
    form: {
      id_sbu: 1,
      brand: { id_brand: 0, discount: '', brand: '', value: '', label: '' },
      model: { id_model: 0, model: '', value: '', label: '', amount: 0 },
      capacity: { id_capacity: 0, capacity: '', value: '', label: '' }
    },
    brandList: [],
    modelList: [],
    capacityList: []
  })
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setData({ ...data, open: false })
  }

  const loadData = async () => {
    setData({ ...data, open: true, loading: true })
    try {
      const res : any = await webApiService.getAllBrandBySbu(String(data.form.id_sbu))

      let modelList: any[]

      if (res.data.map((item: any) => { return { ...item, value: item.id_brand, label: item.brand } }).find((e: any) => e.brand.toUpperCase() === ctx.tradein.brand.toUpperCase()) !== undefined) {
        const marca = res.data.map((item: any) => { return { ...item, value: item.id_brand, label: item.brand } }).find((e: any) => e.brand.toUpperCase() === ctx.tradein.brand.toUpperCase())
        console.log('🚀 ~ loadData ~ marca:', marca)
        modelList = await webApiService.getAllModelByBrand(String(data.form.id_sbu), marca.id_brand).then(res => res.data.map((item: any) => { return { ...item, value: item.id_model, label: item.model } }))
      } else {
        modelList = []
      }
      console.log('🚀 ~ loadData ~ modelList:', modelList)

      setData({
        ...data,
        open: true,
        loading: false,
        brandList: res.data.map((item: any) => { return { ...item, value: item.id_brand, label: item.brand } }),
        form: { ...data.form, brand: res.data.map((item: any) => { return { ...item, value: item.id_brand, label: item.brand } }).find((e: any) => e.brand.toUpperCase() === ctx.tradein.brand.toUpperCase()) },
        modelList
        // modelList: res[2].data.map((item: any) => { return { ...item, value: item.id_model, label: item.model } }),
        // capacityList: res[3].data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity } })
      })
    } catch (error: any) {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const handleBrandChange = (value: any) => {
    console.log('value => ', value)
    setData({
      ...data,
      form: {
        ...data.form,
        brand: value,
        model: { id_model: 0, model: '', value: '', label: '', amount: 0 },
        capacity: { id_capacity: 0, capacity: '', value: '', label: '' }
      }
    })
    setData({ ...data, loading: true })
    webApiService.getAllModelByBrand(String(data.form.id_sbu), String(value.id_brand)).then(res => {
      if (res.success) {
        setData({ ...data, modelList: res.data.map((item: any) => { return { ...item, value: item.id_model, label: item.model } }), loading: false })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  const handleModelChange = (value: any) => {
    console.log('🚀 ~ handleModelChange ~ value:', value)
    setData({ ...data, form: { ...data.form, model: value } })
  }

  const CustomOption = (props: any) => {
    const { data } = props
    // eslint-disable-next-line no-debugger
    // debugger
    return (
      <components.Option {...props}>
        {data.label}
      </components.Option>
    )
  }

  const handleUpdate = async () => {
    setData({ ...data, loading: true })
    await webApiService.updatePCheck({ make: data.form.brand.brand, modelName: data.form.model.model, loginCode: ctx.tradein.login_code, id_user: userState.id, id_tradein: ctx.tradein.id }).then(res => {
      if (res.success) {
        saveCtx({ ...ctx, reload: true })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  return (
    <div>
      <Button
        type='submit'
        variant='shadow'
        size='sm'
        className='ml-2'
        onClick={() => {
          loadData()
        }}
        color='primary'
      >
        Revisar Factibilidad
      </Button>
      <Modal open={data.open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            p: 2
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'white',
              borderRadius: 2,
              textAlign: 'center'
            }}
          >
            <Typography
              id='modal-title'
              variant='h6'
              component='h2'
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                width: '100%',
                padding: 2
              }}
            >
              Revisar Factibilidad:
            </Typography>
            <Paper variant='outlined' sx={{ padding: 2 }} className='small'>
              <p className='text-start mb-2'>Modelo encontrado en Phonecheck:</p>
              <p className='text-start mb-1'>Marca: <span className='fw-bold'>{ctx.tradein.make}</span></p>
              <p className='text-start mb-1'>Modelo: <span className='fw-bold'>{ctx.tradein.modelName}</span></p>
              <p className='text-start mb-1'>Número Modelo: <span className='fw-bold'>{ctx.tradein.modelNo}</span></p>
              <div className='text-start mb-2'>
                <p className='text-start mb-1'>Seleccione marca:</p>
                <Select
                  components={{ Option: CustomOption }}
                  isLoading={data.loading}
                  isDisabled={data.loading}
                  options={data.brandList}
                  value={data.form.brand}
                  onChange={(value) => handleBrandChange(value)}
                  placeholder='Seleccione un marca...'
                />
              </div>
              <div className='text-start mb-2'>
                <p className='text-start mb-1'>Seleccione modelo:</p>
                <Select
                  components={{ Option: CustomOption }}
                  isLoading={data.loading}
                  isDisabled={data.loading}
                  options={data.modelList}
                  value={data.form.model}
                  onChange={(value) => handleModelChange(value)}
                  placeholder='Seleccione un modelo...'
                />
              </div>
            </Paper>
            <Box
              sx={{
                backgroundColor: '#f1f1f1',
                width: '100%',
                padding: 2,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                display: 'flex',
                justifyContent: 'center',
                gap: 2
              }}
            >
              <Button
                color='danger'
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button isLoading={data.loading} color='primary' type='submit' onClick={handleUpdate}>
                Guardar Modelo
              </Button>
            </Box>
          </Box>

        </Box>
      </Modal>
    </div>
  )
}

export default CheckFeasibility
