import React from 'react'
import { useTradeinContext } from '../hooks/useTradeinContext'

const DeviceMobile: React.FC<any> = () => {
  const { ctx } = useTradeinContext()

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Dispositivo</h6>
      <div className='grid grid-cols-12'>
        <div className='col-span-12'>
          <p className='mb-2'><strong>Tipo:</strong> {ctx.tradein.sbu}</p>
        </div>
        <div className='col-span-12'>
          <p className='mb-2'><strong>Marca:</strong> <span className={ctx.tradein.brand !== ctx.tradein.make && ctx.tradein.recalculate === '1' ? 'line-through' : ''}>{ctx.tradein.brand}</span> {ctx.tradein.brand !== ctx.tradein.make && ctx.tradein.recalculate === '1' ? <span className='fw-bold'>{ctx.tradein.make}</span> : null}</p>
        </div>
        <div className='col-span-12'>
          <p className='mb-2'><strong>Modelo:</strong> <span className={ctx.tradein.model !== ctx.tradein.modelName && ctx.tradein.recalculate === '1' ? 'line-through' : ''}>{ctx.tradein.model}</span> {ctx.tradein.model !== ctx.tradein.modelName && ctx.tradein.recalculate === '1' ? <span className='fw-bold'>{ctx.tradein.modelName}</span> : null}</p>
        </div>
        <div className='col-span-12'>
          <p className='mb-2'><strong>Capacidad:</strong> <span className={ctx.tradein.capacity !== ctx.tradein.memory && ctx.tradein.recalculate === '1' ? 'line-through' : ''}>{ctx.tradein.capacity}</span> {ctx.tradein.capacity !== ctx.tradein.memory && ctx.tradein.recalculate === '1' ? <span className='fw-bold'>{ctx.tradein.memory}</span> : null}</p>
        </div>
        {ctx.tradein.sbu === 'Notebook' && (
          <>
            <div className='col-span-12'>
              <p className='mb-2'><strong>CPU:</strong> {ctx.tradein.cpu}</p>
            </div>
            <div className='col-span-12'>
              <p className='mb-2'><strong>Generación del CPU:</strong> {ctx.tradein.cpuGeneration}</p>
            </div>
            <div className='col-span-12'>
              <p className='mb-2'><strong>Pantalla:</strong> {ctx.tradein.screenSize}</p>
            </div>
            <div className='col-span-12'>
              <p className='mb-2'><strong>Memoria RAM:</strong> {ctx.tradein.ram}</p>
            </div>
            <div className='col-span-12'>
              <p className='mb-2'><strong>Almacenamiento:</strong> {ctx.tradein.storage}</p>
            </div>
          </>
        )}
        <div className='col-span-12'>
          <div><strong>IMEI: </strong>
            {ctx.tradein.id_status === 1
              ? (
                <div className='bg-blue-200 p-4 border-blue-900'>
                  Se requiere el resultado de la auto evaluación para obtener el imei.
                </div>
                )
              : ctx.tradein.id_status === 6
                ? <div className='bg-red-200 p-4 border-red-900'>Evaluación fallida</div>
                : ctx.tradein.serie}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeviceMobile
