export const terms = (terms: string) => {
  const termsText = terms.split('\n').map((paragraph, index) => {
    if (paragraph.includes('Link T&C:')) {
      const link = paragraph.split('Link T&C:')[1].trim()
      return (
        <p key={index} className='text-xs text-gray-500 mt-2'>
          <a href={link} target='_blank' rel='noopener noreferrer'>T&C aquí</a>.
        </p>
      )
    }

    return (
      <p key={index} className='text-xs text-gray-500 mt-2'>
        {paragraph}
      </p>
    )
  })

  return (
    <div className='px-2 md:px-20 lg:px-60 xl:px-80 mt-10'>
      {termsText}
    </div>
  )
}
