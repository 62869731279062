import { Button, Input, Select, Radio, RadioGroup, Link, SelectItem, Spinner } from '@nextui-org/react'
import React, { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { IConfigSeller } from '@/models/configSeller'
import { IAppStore } from '@/models/store.model'
import { webApiService } from '@/services'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTradeinContext } from '../hooks/useTradeinContext'
import NUIAlert from '@/components/Alert/Alert'

const Contract: React.FC<any> = () => {
  const { ctx, saveCtx } = useTradeinContext()
  const [loading, setLoading] = useState(false)
  const userState = useSelector((store: IAppStore) => store.user)
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const { slug } = useParams<{ slug: string }>()

  const handleChangeRUT = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, rut: event.target.value })
  }

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, address: event.target.value })
  }

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, state: event.target.value })
  }

  const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, city: event.target.value })
  }

  const handleChangeCivilState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    saveCtx({ ...ctx, civil_state: event.target.value })
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, emailRecipient: event.target.value })
  }

  const handleSave = () => {
    setLoading(true)
    webApiService.update({
      stage: 4,
      id: ctx.tradein.id,
      name: String(ctx.tradein.name),
      lastname: String(ctx.tradein.lastname),
      email: ctx.emailRecipient === 'cliente' ? String(ctx.tradein.email) : userState.email,
      send_type: String(ctx.emailRecipient),
      rut: String(ctx.rut),
      city: ctx.city,
      address: String(ctx.address),
      state: String(ctx.state),
      civil_state: String(ctx.civil_state),
      store_user: String(userState.id),
      sbu: String(ctx.tradein.sbu),
      brand: ctx.tradein.wasRecalculated === 1 ? String(ctx.tradein.make) : String(ctx.tradein.brand),
      model: ctx.tradein.wasRecalculated === 1 ? String(ctx.tradein.modelName) : String(ctx.tradein.model),
      imei: String(ctx.tradein.serie),
      discount_amount: ctx.tradein.wasRecalculated === 1 ? String(ctx.tradein.new_discount_amount) : String(ctx.tradein.discount_amount),
      seller: slug,
      docusign_id: ctx.tradein.serie + '-' + ctx.tradein.id + '-' + ctx.tradein.id_seller,
      id_seller: ctx.tradein.id_seller
    })
      .then(() => {
        enqueueSnackbar('Registro actualizado', { variant: 'success' })
        saveCtx({ ...ctx, reload: true })
        console.log('ctx ==>', ctx)
      })
      .catch((error) => {
        enqueueSnackbar('Hubo un error al intentar guardar el registro.', { variant: 'error' })
        saveCtx({ ...ctx, reload: false })
        console.error('error ==>', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Contrato</h6>
      {
          ctx.displayContract === 0
            ? <NUIAlert type='info'>No disponible por el momento.</NUIAlert>
            : ctx.tradein.isBatteryHealthy === null
              ? <NUIAlert type='info'>Se requiere adjuntar imagen de capacidad de carga del dispositivo (Descuentos) para firmar el contrato.</NUIAlert>
              : loading
                ? <Spinner />
                : (
                    (ctx.tradein.rut === null && [4].includes(Number(ctx.tradein.id_status)) && ctx.tradein.recalculate === '0') ||
                    (ctx.tradein.rut === null && [4].includes(Number(ctx.tradein.id_status)) && ctx.tradein.recalculate === '1' && ctx.tradein.wasRecalculated === 1)
                      ? (
                        <div className='grid grid-cols-12 gap-4'>
                          <div className='col-span-12 md:col-span-4'>
                            <h3 className='my-2'>{String(import.meta.env.VITE_LABEL_RUT)}</h3>
                            <Input
                              value={ctx.rut}
                              onChange={handleChangeRUT}
                            />
                          </div>

                          {
                            configSeller.config.permisos.ENABLE_DIR_CONTRACT === 1 && (
                              <>
                                <div className='col-span-12 md:col-span-4'>
                                  <h3 className='my-2'>Dirección</h3>
                                  <Input
                                    value={ctx.address}
                                    onChange={handleChangeAddress}
                                  />
                                </div>
                                <div className='col-span-12 md:col-span-4'>
                                  <h3 className='my-2'>Ciudad</h3>
                                  <Input
                                    value={ctx.city}
                                    onChange={handleChangeCity}
                                  />
                                </div>
                                {
                            configSeller.config.permisos.ENABLE_DIR_STATE_CONTRACT === 1 && (
                              <div className='col-span-12 md:col-span-4'>
                                <h3 className='my-2'>Estado</h3>
                                <Input
                                  value={ctx.state}
                                  onChange={handleChangeState}
                                />
                              </div>
                            )
                              }
                              </>
                            )
                          }
                          {
                            configSeller.config.permisos.ENABLE_CIVIL_STATE_CONTRACT === 1 && (
                              <div className='col-span-12 md:col-span-4'>
                                <h3 className='my-2'>Estado civil</h3>
                                <Select
                                  value={ctx.civil_state}
                                  onChange={handleChangeCivilState}
                                  placeholder='Seleccione'
                                >
                                  <SelectItem key='soltero' value='soltero'>Soltero</SelectItem>
                                  <SelectItem key='casado' value='casado'>Casado</SelectItem>
                                  <SelectItem key='viudo' value='viudo'>Viudo</SelectItem>
                                  <SelectItem key='divorciado' value='divorciado'>Divorciado</SelectItem>
                                </Select>
                              </div>
                            )
                          }
                          <div className='col-span-12 md:col-span-4'>
                            <RadioGroup orientation='horizontal' label='Enviar contrato al correo' onChange={(value) => handleChangeEmail(value)} className='mt-4'>
                              <Radio value='cliente'>Cliente</Radio>
                              <Radio value='modulo'>Módulo</Radio>
                            </RadioGroup>
                          </div>
                          <div className='col-span-12 md:col-span-4'>
                            <Button
                              color='primary'
                              onClick={handleSave}
                              disabled={
                                [Number(import.meta.env.VITE_PERFIL_SOLOLECTURA)].includes(userState.id_role) ||
                                ctx.rut === '' ||
                                ctx.emailRecipient === '' ||
                                (configSeller.config.permisos.ENABLE_DIR_CONTRACT === 1 && (ctx.address === '' || ctx.city === '')) ||
                                (configSeller.config.permisos.ENABLE_DIR_STATE_CONTRACT === 1 && (ctx.state === '')) ||
                                (configSeller.config.permisos.ENABLE_CIVIL_STATE_CONTRACT === 1 && (ctx.civil_state === ''))
                                }
                            >
                              Guardar
                            </Button>
                          </div>
                        </div>
                        )
                      : ctx.tradein.rut !== null && [3, 4, 5, 7, 8, 9].includes(Number(ctx.tradein.id_status)) && (
                        <div className='col-span-12 md:col-span-4'>
                          <p className='my-4'>N° identificador de Contrato: <strong>{ctx.tradein.docusign_id}</strong></p>
                          {
                              ctx.tradein.url_contract && (
                                <Link href={ctx.tradein.url_contract} target='_blank' rel='noreferrer'>
                                  <Button>Descargar</Button>
                                </Link>
                              )
                            }
                          <NUIAlert type='success'>Contrato creado y enviado a: <strong>{ctx.tradein.contract_email}</strong></NUIAlert>
                        </div>
                      )
                  )
        }
    </div>
  )
}

export default Contract
