import moment from 'moment'
import { currencyFormat } from './currencyFormat'

export const htmlReplaceContract: (html: string, data: any) => string = (html, data) => {
  if (data.seller === 'coppel') {
    const fecha = moment().locale('es').format('DD/MM/YYYY')
    const fullname = String(data.name) + ' ' + String(data.lastname)
    const direccion: string = data.address
    const identificacion: string = data.rut
    const ciudad: string = data.city
    const marca: string = data.brand
    const modelo: string = data.model
    const imei: string = data.imei

    html = html.replaceAll('{{fecha}}', fecha)
      .replaceAll('{{fullname}}', fullname)
      .replaceAll('{{direccion}}', direccion)
      .replaceAll('{{identificacion}}', identificacion)
      .replaceAll('{{ciudad}}', ciudad)
      .replaceAll('{{marca}}', marca)
      .replaceAll('{{modelo}}', modelo)
      .replaceAll('{{imei}}', imei)
  }

  if (data.seller === 'falabella') {
    console.log('🚀 ~ htmlReplaceContract ~ data', data)

    const fecha = moment().locale('es').format('DD/MM/YYYY')
    const fullname = String(data.name) + ' ' + String(data.lastname)
    const identificacion: string = data.rut
    const marca: string = data.brand
    const modelo: string = data.model
    const imei: string = data.imei
    const discount = currencyFormat(Number(data.discount_amount), 'CL')
    const address: string = data.address + ', ' + data.city
    const sbu: string = data.sbu
    const civilState: string = data.civil_state

    html = html.replaceAll('{{fecha}}', fecha)
      .replaceAll('{{fullname}}', fullname)
      .replaceAll('{{identificacion}}', identificacion)
      .replaceAll('{{marca}}', marca)
      .replaceAll('{{modelo}}', modelo)
      .replaceAll('{{imei}}', imei)
      .replaceAll('{{discount}}', discount)
      .replaceAll('{{domicilio}}', address)
      .replaceAll('{{sbu}}', sbu)
      .replaceAll('{{estadoCivil}}', civilState)
  }

  return html
}
