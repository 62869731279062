export const PublicRoutes = {
  ROOT: '/',
  SLUG: '/:slug',
  LOGIN: '/:slug/backoffice/login',
  NOTFOUND: '/not-found',
  CONTRACT: '/:slug/contract/:loginCode'
}

export const PrivateRoutes = {
  HOME: '/:slug/backoffice/',
  RECIBIDOS: '/:slug/backoffice/recibidos',
  ENCUSTODIA: '/:slug/backoffice/custodia',
  RETIRADOS: '/:slug/backoffice/retirados',
  TRADEIN: '/:slug/backoffice/trade-in/:id',
  PICKUP_FORM: '/:slug/backoffice/pickup-form'
}
