import { type FC } from 'react'
import LogoTradein from '@/assets/images/tradein.png'
import { Avatar, Image } from '@nextui-org/react'
import { IConfigSeller } from '@/models/configSeller'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import RoleChildrenGuard from '@/guards/RoleChildrenGuard'
import { PrivateRoutes } from '@/constants/routes'
import { IUser } from '@/models/user.model'
import { MdLogout } from 'react-icons/md'
import { useAppDispatch } from '@/store/store'
import { resetUser } from '@/store/user'

const Header: FC = () => {
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const userState = useSelector((store: { user: IUser }) => store.user)
  const { slug } = useParams<{ slug: string }>()
  const dispatch = useAppDispatch()

  return (
    <header className='p-2 bg-white shadow relative h-[100px] md:h-[64px]'>
      <div className='grid grid-cols-12 h-full'>
        <div className='col-span-12 md:col-span-4 flex items-center justify-center md:justify-start'>
          <Image src={configSeller.config.logo} alt='Logo' className='h-[30px] rounded-none' />
          <Image src={LogoTradein} alt='Logo' className='h-[30px] rounded-none ml-4' />
        </div>
        <div className='col-span-8 md:col-span-4 flex items-center justify-center gap-2'>
          <RoleChildrenGuard roles={[Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_JEFETIENDA), Number(import.meta.env.VITE_PERFIL_CUSTODIA)]} PublicValidationFragment={null}>
            <Link to={PrivateRoutes.HOME.replace(':slug', String(slug))} className={location.pathname === PrivateRoutes.HOME.replace(':slug', String(slug)) ? 'text-black font-black underline' : 'text-black font-black text-decoration-none'}>HOME</Link>
            <Link to={PrivateRoutes.PICKUP_FORM.replace(':slug', String(slug))} className={location.pathname === PrivateRoutes.PICKUP_FORM.replace(':slug', String(slug)) ? 'text-black font-black underline' : 'text-black font-black text-decoration-none'}>FORMULARIO PICK UP</Link>
          </RoleChildrenGuard>
        </div>
        <div className='col-span-4 md:col-span-4 flex items-center justify-end'>
          <div className='flex items-center'>
            <Avatar name={userState.name[0]} className='bg-black text-white font-black text-xl hidden md:block' />
            <div className='mx-2 hidden md:block'>
              <p className='font-bold mb-0'>{userState.name} ({userState.name_role.replace('TI_', '')})</p>
              <p>{userState.email}</p>
            </div>
            <MdLogout className='text-black text-5xl cursor-pointer rounded-full hover:bg-gray-300 p-2' onClick={() => { dispatch(resetUser()) }} />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
