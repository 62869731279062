import { PrivateRoutes } from '@/constants/routes'
import { Tab, Tabs } from '@nextui-org/react'
import React from 'react'
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'
import ListCard from './components/ListCard/ListCard'
import TablePrincipal from './components/TablePrincipal/TablePrincipal'
import TableCustody from './components/TableCustody/TableCustody'
import TableRetirados from './components/TableRetirados/TableRetirados'
import TableRecibidos from './components/TableRecibidos/TableRecibidos'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'

const HomePage: React.FC = () => {
  const { pathname } = useLocation()
  const userState = useSelector((store: any) => store.user)

  const { slug } = useParams()
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }} className='space-y-4'
    >
      {[Number(import.meta.env.VITE_PERFIL_SOLOLECTURA), Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_JEFETIENDA), Number(import.meta.env.VITE_PERFIL_EJECUTIVO), Number(import.meta.env.VITE_PERFIL_ADMIN_SELLER)].includes(userState.id_role) ? <ListCard /> : null}
      <div className='bg-white rounded-2xl shadow-md p-2 mt-4'>
        <Tabs selectedKey={pathname} aria-label='Tabs' size='lg' className={[Number(import.meta.env.VITE_PERFIL_CUSTODIA)].includes(userState.id_role) ? '!hidden' : ''}>
          <Tab className='text-tiny px-1 md:px-3' id={PrivateRoutes.HOME.replace(':slug', String(slug))} key={PrivateRoutes.HOME.replace(':slug', String(slug))} href={PrivateRoutes.HOME.replace(':slug', String(slug))} title='Principal' />
          <Tab className='text-tiny px-1 md:px-3' id={PrivateRoutes.RECIBIDOS.replace(':slug', String(slug))} key={PrivateRoutes.RECIBIDOS.replace(':slug', String(slug))} href={PrivateRoutes.RECIBIDOS.replace(':slug', String(slug))} title='Equipos recibidos' />
          {[Number(import.meta.env.VITE_PERFIL_SOLOLECTURA), Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_JEFETIENDA)].includes(userState.id_role) ? <Tab className='text-tiny px-1 md:px-3' id={PrivateRoutes.ENCUSTODIA.replace(':slug', String(slug))} key={PrivateRoutes.ENCUSTODIA.replace(':slug', String(slug))} href={PrivateRoutes.ENCUSTODIA.replace(':slug', String(slug))} title='Equipos custodia' /> : null}
          {[Number(import.meta.env.VITE_PERFIL_SOLOLECTURA), Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_JEFETIENDA)].includes(userState.id_role) ? <Tab className='text-tiny px-1 md:px-3' id={PrivateRoutes.RETIRADOS.replace(':slug', String(slug))} key={PrivateRoutes.RETIRADOS.replace(':slug', String(slug))} href={PrivateRoutes.RETIRADOS.replace(':slug', String(slug))} title='Equipos retirados' /> : null}
        </Tabs>
        <Routes>
          <Route path='/' element={[Number(import.meta.env.VITE_PERFIL_CUSTODIA)].includes(userState.id_role) ? <TableRecibidos /> : <TablePrincipal />} />
          <Route path='/recibidos' element={<TableRecibidos />} />
          <Route path='/custodia' element={<TableCustody />} />
          <Route path='/retirados' element={<TableRetirados />} />
          <Route path='/*' element={<Navigate replace to={PrivateRoutes.HOME} />} />
        </Routes>
      </div>
    </motion.div>
  )
}

export default HomePage
