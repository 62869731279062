import { IConfigSeller } from '@/models/configSeller'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import Certificate from './components/Certificate'
import Checklist from './components/Checklist'
import Client from './components/Client'
import Contract from './components/Contract'
import Detail from './components/Detail'
import DeviceMobile from './components/DeviceMobile'
import DeviceOther from './components/DeviceOther'
import Discounts from './components/Discounts'
import Header from './components/Header'
import History from './components/History'
import Multimedia from './components/Multimedia'
import Questions from './components/Questions'
import { TradeinProvider } from './context/TradeinContext'
import { useTradeinContext } from './hooks/useTradeinContext'

const TradeInPageContext = () => {
  const { ctx } = useTradeinContext()
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }} className='space-y-4'
    >
      <Header />
      <div className='grid grid-cols-12 gap-4 mt-4'>
        <div className='col-span-12 md:col-span-3'>
          <Detail />
        </div>
        <div className='col-span-12 md:col-span-9'>
          <Client />
          <div className='grid grid-cols-12 gap-4 mt-4'>
            <div className='col-span-12 md:col-span-8'>
              {(ctx.tradein.sbu === 'Smartphone') ? <DeviceMobile /> : <DeviceOther />}
            </div>
            <div className='col-span-12 md:col-span-4'>
              <History />
            </div>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-12 gap-4 mt-4'>

        <div className='col-span-12'>
          <Questions />
        </div>

        {ctx.tradein.id_status === 6 && ctx.tradein.sbu === 'Smartphone'
          ? (
            <div className='col-span-12'><div className='text-sm p-4 bg-red-100 border border-red-300 rounded-md text-black'>Lo sentimos, este dispositivo no aprobó la evaluación de Phonecheck. Puede reenviar el código para realizar una nueva evaluación.</div></div>
            )
          : null}

        {
          ctx.tradein.id_status !== 6
            ? (
              <>
                <div className={configSeller.config.permisos.ENABLE_CHECK_BATTERY === 1 && (ctx.tradein.entry_form_status === 1 || ctx.tradein.certificate_theft === 'VALID') ? 'col-span-12 md:col-span-6' : 'col-span-12'}>
                  {ctx.tradein.sbu === 'Smartphone'
                    ? <Certificate />
                    : <Checklist />}
                </div>
                {/* {
                  configSeller.config.permisos.ENABLE_CHECK_BATTERY === 1 && (ctx.tradein.entry_form_status === 1 || ctx.tradein.certificate_theft === 'VALID')
                    ? (
                      <div className='col-span-12 md:col-span-6'>
                        <Discounts />
                      </div>
                      )
                    : null
                } */}
                <div className={ctx.tradein.id_status !== 1 ? 'col-span-12 md:col-span-6' : 'col-span-12'}>
                  <Discounts />
                </div>

                {ctx.displayContract === 1 &&
                  <div className='col-span-12'>
                    <Contract />
                  </div>}
                {/* {
                  (ctx.displayContract === 0 && ctx.tradein.id_status === 4 && ctx.tradein.isBatteryHealthy !== null) || (ctx.tradein.id_status !== 1 && ctx.tradein.id_status !== 2 && ctx.tradein.id_status !== 4)
                    ? (<div className='col-span-12'>  <Multimedia /> </div>)
                    : null
                } */}
                <div className='col-span-12'>  <Multimedia /> </div>

              </>
              )
            : null
        }

      </div>
    </motion.div>
  )
}

const TradeInPage = () => {
  return (
    <TradeinProvider><TradeInPageContext /></TradeinProvider>
  )
}

export default TradeInPage
