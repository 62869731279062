import { useParams } from 'react-router-dom'

import { webApiService } from '@/services'
import { useEffect, useState } from 'react'

const Questions = () => {
  const { id } = useParams()
  const [data, setData] = useState<{ loading: boolean, list: any[] }>({ loading: false, list: [] })

  const loadData = (): void => {
    setData({ ...data, loading: true })
    webApiService.getAllAnswers(String(id)).then(res => {
      setData({ loading: false, list: res.data })
    })
      .catch((error) => {
        console.log('Error loading data:', error)
      })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Preguntas evaluación</h6>
      <div>
        {
          data.list.map((item: any, index: number) => (
            <div key={index}>
              <p className=''>{item.question}</p>
              <p className='fw-bold font-bold mb-2'>{item.answer}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Questions
