import { NextUIProvider } from '@nextui-org/react'
import { AnimatePresence } from 'framer-motion'
import { FC } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import LoadSeller from './components/LoadSeller/LoadSeller'
import { PrivateRoutes, PublicRoutes } from './constants/routes'
import AuthRoleRouterGuard from './guards/AuthRoleRouterGuard'
import ContractPage from './pages/backoffice/ContractPage/ContractPage'
import HomePage from './pages/backoffice/HomePage/HomePage'
import LoginPage from './pages/backoffice/LoginPage/LoginPage'
import NotFoundPage from './pages/backoffice/NotFoundPage/NotFoundPage'
import PickupForm from './pages/backoffice/PickupForm/PickupForm'
import TradeInPage from './pages/backoffice/TradeInPage/TradeInPage'
import { HomePageProvider } from './pages/cliente/FormularioClientePage/context/FormularioClienteContext'
import FormularioClientePage from './pages/cliente/FormularioClientePage/FormularioClientePage'
import './theme/App.css'

const App: FC = () => {
  const navigate = useNavigate()
  // const location = useLocation()

  return (
    <NextUIProvider navigate={navigate}>
      <AnimatePresence mode='wait'>
        <Routes>
          <Route path={PublicRoutes.NOTFOUND} element={<NotFoundPage />} />
          <Route path={PublicRoutes.ROOT} element={<LoadSeller />}>
            <Route path={PublicRoutes.CONTRACT} element={<ContractPage />} />
            <Route path={PublicRoutes.ROOT} element={<h1>Es necesario ingresar un slug</h1>} />
            <Route path={PublicRoutes.SLUG} element={<HomePageProvider><FormularioClientePage /></HomePageProvider>} />
            <Route path={PublicRoutes.LOGIN} element={<LoginPage />} />
            <Route element={<Layout />}>
              <Route element={<AuthRoleRouterGuard roles={[Number(import.meta.env.VITE_PERFIL_SOLOLECTURA), Number(import.meta.env.VITE_PERFIL_CUSTODIA), Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_EJECUTIVO), Number(import.meta.env.VITE_PERFIL_JEFETIENDA), Number(import.meta.env.VITE_PERFIL_COURIER), Number(import.meta.env.VITE_PERFIL_ADMIN_SELLER)]} />}>
                <Route path={PrivateRoutes.HOME + '*'} element={<HomePage />} />
              </Route>
              <Route element={<AuthRoleRouterGuard roles={[Number(import.meta.env.VITE_PERFIL_CUSTODIA), Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_EJECUTIVO), Number(import.meta.env.VITE_PERFIL_JEFETIENDA), Number(import.meta.env.VITE_PERFIL_COURIER), Number(import.meta.env.VITE_PERFIL_ADMIN_SELLER)]} />}>
                <Route path={PrivateRoutes.PICKUP_FORM} element={<PickupForm />} />
              </Route>
              <Route element={<AuthRoleRouterGuard roles={[Number(import.meta.env.VITE_PERFIL_SOLOLECTURA), Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_EJECUTIVO), Number(import.meta.env.VITE_PERFIL_JEFETIENDA), Number(import.meta.env.VITE_PERFIL_COURIER), Number(import.meta.env.VITE_PERFIL_ADMIN_SELLER)]} />}>
                <Route path={PrivateRoutes.TRADEIN} element={<TradeInPage />} />
              </Route>
            </Route>
            <Route path={PrivateRoutes.HOME + '*'} element={<NotFoundPage />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </NextUIProvider>
  )
}

export default App
